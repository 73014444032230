import useTranslation from 'next-translate/useTranslation'

import FeaturedProducts from './FeaturedProducts'

import styles from '../../styles/LandingPage/ContentAndFeaturedProducts.module.less'

const ContentAndFeaturedProducts = () => {
  const { t } = useTranslation('landing')

  return (
    <section className={styles['content-and-featured-products-wrapper']}>
      <h2 className="h1">{t('landing:Modern sourcing starts here')}</h2>
      <p className={styles.description}>{t('landing:Source EU quality supplies that meet your requirements and operations’ preferences')}</p>
      <FeaturedProducts callToAction={t('Browse products')} label={t('landing:Best sellers with volume prices')} />
    </section>
  )
}

export default ContentAndFeaturedProducts
