import { FC, MouseEvent, ReactNode } from 'react'
import { Button, FlexboxGrid } from 'rsuite'

import Avatar from './Avatar/Avatar'

import styles from '../styles/CTACard.module.less'
import buttonStyles from '../styles/CustomButtons.module.less'

interface CTACardProps {
  className?: string
  title: string
  buttonLabel: ReactNode
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  avatarUrl: string
}

const CTACard: FC<CTACardProps> = (props) => {
  const {
    title,
    buttonLabel,
    onClick,
    avatarUrl,
    className,
  } = props

  return (
    <FlexboxGrid
      className={`${styles['cta-card-container']} ${className || ''} margin-top-spacer-double`}
    >
      <FlexboxGrid.Item className="margin-right-spacer-double">
        <Avatar
          publicFolderImageSrc={avatarUrl}
          altName="Call to action avatar"
          size="sm"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as="ul" colspan={18} className={`padding-left-spacer-double ${styles['cta-wrapper']}`}>
        <li>
          <p className={styles.title}>
            {title}
          </p>
        </li>
        <li>
          <Button
            data-testid="open-cta-card-container-modal"
            className={`${
              styles['contact-us-button']}
              ${buttonStyles['custom-button']}
              ${buttonStyles['button-caret']}
              ${buttonStyles['button-dim']}
              `}
            type="button"
            onClick={onClick}
          >
            <b>{buttonLabel}</b>
          </Button>
        </li>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default CTACard
