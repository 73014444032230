import { FC } from 'react'
import { Col, Grid, Row } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import SafeTranslate from '../common/SafeTranslate'
import MainCategoriesCarousel from './MainCategoriesCarousel/MainCategoriesCarousel'

import styles from '../../styles/LandingPage/LandingPage.module.less'

const FeaturesBanner: FC = () => {
  const { t } = useTranslation('landing')
  return (
    <>
      <Grid fluid className={styles['features-banner']}>
        <Row className={`${styles['features-introduction']} rs-flex-box-grid-center rs-flex-box-grid`}>
          <Col>
            <h2 className="h4">
              {t('Why Droppe?')}
            </h2>
            <p className={`${styles.headline} h1`}>
              {t('Take the Chasing out of Purchasing')}
            </p>
            <p>
              <SafeTranslate
                i18nKey="landing:Leverage your order volumes to find the right supplies at the best prices, fast—while saving valuable time on reordering and supplier conversations"
                components={{
                  span: <span className="highlight-text" />,
                  br: <br />,
                }}
              />
            </p>
          </Col>
        </Row>
        <MainCategoriesCarousel
          hideArrowsFade
          label={t('10,000 SKUs from Europe’s leading B2B brands')}
          className={styles['features-banner-carousel']}
        />
      </Grid>
    </>
  )
}

export default FeaturesBanner
